import axios from 'axios';
import JwtDecode from 'jwt-decode';
import moment from 'moment-timezone';

import { createAbility } from '~/helpers/ability-helper';
import UserPreferences from '~/helpers/user-preferences-helper';
import { actions } from '~/redux/ducks/user';

const TOKEN_KEY = 'TOKEN_KEY';
const USER_KEY = 'USER_KEY';

export const setAuthenticationUser = user => {
    return async (dispatch, getState) => {
        await UserPreferences.setItem(USER_KEY, user);
        dispatch(actions.setUser(user));
    };
};

const setAuthenticationToken = ({ token, keepLoggedIn }) => {
    return async (dispatch, getState) => {
        await UserPreferences.setItem(TOKEN_KEY, token, { temporary: !keepLoggedIn });
        dispatch(actions.setToken(token));
    };
};

export const setAuthenticationData = ({
    token, user, rules, skipRefreshToken,
}) => {
    return async (dispatch, getState) => {
        await dispatch(setAuthenticationToken({ token, keepLoggedIn: true }));
        await dispatch(setAuthenticationUser(user));
        dispatch(actions.setSkipRefreshToken(skipRefreshToken));
        dispatch(actions.setAbility(createAbility(rules)));
    };
};

export const removeAuthenticationData = () => {
    return async (dispatch, getState) => {
        try {
            window.FB.logout(); // Facebook
        } catch (ex) { console.warn(ex); }
        try {
            window.auth2.signOut(); // Google
        } catch (ex) { console.warn(ex); }
        UserPreferences.clear();
        dispatch(actions.logoutUser());
        dispatch(actions.setAbility(createAbility([])));
    };
};

const decodeToken = token => {
    try {
        return JwtDecode(token);
    } catch (ex) {
        console.warn(ex);
    }
    return null;
};

export const loadAuthenticationData = () => {
    return async (dispatch, getState) => {
        const token = await UserPreferences.getItem(TOKEN_KEY);
        if (!token) {
            return false;
        }

        const payload = decodeToken(token);
        if (!payload) {
            return false;
        }

        const expirationDate = new Date(payload.ext * 1000);
        if (moment().isAfter(expirationDate)) {
            await dispatch(removeAuthenticationData());
            return false;
        }

        const user = await UserPreferences.getItem(USER_KEY);
        dispatch(actions.setToken(token));
        dispatch(actions.setUser(user));
        dispatch(actions.setSkipRefreshToken(false));

        return true;
    };
};

export const refreshUserAuthentication = () => {
    return async (dispatch, getState) => {
        try {
            const options = await UserPreferences.options(TOKEN_KEY);
            const response = await axios.put('/login');

            return dispatch(setAuthenticationData({
                ...response.data,
                keepLoggedIn: !options.temporary,
            }));
        } catch (ex) {
            const { response } = ex;
            if (response && response.status === 403) {
                return dispatch(removeAuthenticationData());
            }
            throw ex;
        }
    };
};

export default {};
