import moment from 'moment-timezone';

import store from '~/redux/store';

export function tokenInterceptor(requestConfig) {
    const { token } = store.getState();

    if (token && !requestConfig.headers.Authorization) {
        // eslint-disable-next-line no-param-reassign
        requestConfig.headers.Authorization = `Bearer ${token}`;
    }
    // eslint-disable-next-line no-param-reassign
    requestConfig.headers.timezone = moment.tz.guess();

    return requestConfig;
}
