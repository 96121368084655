import React from 'react';

import { Route } from 'react-router-dom';
import urlJoin from 'url-join';

import store from '~/redux/store';

export const getRoutesArray = route => {
    if (route.group) {
        return route.routes.reduce((result, r) => {
            return [...result, ...getRoutesArray(r)];
        }, []);
    }
    return [route];
};

// Private routes

export const renderRoute = (route, index, prefix) => {
    const { component: Comp } = route;
    return (
        <Route
            key={route.path || index}
            path={prefix ? urlJoin(prefix, route.path) : route.path}
            exact={route.exact}
            component={Comp}
        />
    );
};

export const findParentGroups = (routeThree, route) => {
    const path = [];
    const getPath = (root, dest) => {
        if (root === dest) return true;
        if (root.group && root.routes.some(r => getPath(r, dest))) {
            path.push(root);
            return true;
        }
        return false;
    };
    getPath({
        group: 'root',
        routes: routeThree,
    }, route);
    return path;
};

const checkAbilityPermission = route => {
    if (!Array.isArray(route.permissions)) {
        return true;
    }
    return route.permissions.some(permission => {
        const { can, an } = permission;
        return store.getState().ability.can(can, an);
    });
};

const checkRolePermission = route => {
    if (!Array.isArray(route.roles)) {
        return true;
    }

    const { user } = store.getState();
    return route.roles.includes(user?.role);
};

export const hasPermission = route => {
    if (!checkRolePermission(route)) {
        return false;
    }

    if (!checkAbilityPermission(route)) {
        return false;
    }

    return true;
};
