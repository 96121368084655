import React, { memo, useMemo } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const RedirectToLogin = props => {
    const to = useMemo(() => ({
        pathname: '/login',
        state: { from: props.location },
    }), [props.location]);
    return (
        <Redirect
            to={to}
        />
    );
};

RedirectToLogin.propTypes = {
    location: PropTypes.string.isRequired,
};

const PrivateRoute = memo(({
    render,
    component,
    user, ...others
}) => {
    return (
        <Route
            {...others}
            render={user ? render : RedirectToLogin}
            component={user ? component : memo(RedirectToLogin)}
        />
    );
});

PrivateRoute.propTypes = {
    ...Route.propTypes,
};

const mapStateToProps = ({ user }) => {
    return {
        user,
    };
};

export default connect(mapStateToProps)(PrivateRoute);
