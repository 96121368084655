import React, { memo, useCallback } from 'react';

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import {
    Layout,
    Button, Popover,
    Divider,
} from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import ScreenSizeDebugger from '~/components/ScreenSizeDebugger';
import UserAvatar from '~/components/UserAvatar';
import { connectScreenSize } from '~/helpers/screen-size-helper';
import { actions } from '~/redux/ducks';
import thunks from '~/redux/thunks';

import styles from './AppHeaderBar.module.scss';

const { Header } = Layout;

const AppHeaderBar = memo(({
    toggleSidebar, sidebarCollapsed, removeAuthenticationData,
    history, user,
}) => {
    const handleLogout = useCallback(() => {
        removeAuthenticationData();
        history.push('/login');
    }, [history, removeAuthenticationData]);

    const isPsychologist = user.role === 'PSYCHOLOGIST' && user.profile.psychologist;

    const renderUserOptions = () => {
        return (
            <div>
                <div className={styles.profile}>
                    <b>{user.name}</b>
                </div>
                <Divider className={styles.optionDivider} />
                {isPsychologist
                    ? (
                        <>
                            <Link
                                to="/app/perfil"
                            >
                                Perfil
                            </Link>
                            <Divider className={styles.optionDivider} />
                        </>
                    ) : null}
                <a onClick={handleLogout} role="button" tabIndex={0}>
                    Sair
                </a>
            </div>
        );
    };

    const renderAvatar = () => {
        const { profile, email } = user;
        const { avatar } = profile;

        return (
            <Popover
                placement="bottomRight"
                content={renderUserOptions()}
                trigger="focus"
            >
                <a onClick={null} role="button" tabIndex="-1">
                    <UserAvatar
                        photo={avatar?.path}
                        name={profile?.name}
                        email={email}
                        className={styles.avatar}
                        hidePopover
                    />
                </a>
            </Popover>
        );
    };

    return (
        <Header className={styles.header}>

            <div className={styles.headerContainer}>
                <div className={styles.menuButton}>
                    <Button onClick={toggleSidebar}>
                        {sidebarCollapsed
                            ? (
                                <MenuUnfoldOutlined />
                            ) : (
                                <MenuFoldOutlined />
                            )}
                    </Button>
                </div>

                <ScreenSizeDebugger />

                <div className={styles.avatarContainer}>
                    {renderAvatar()}
                </div>
            </div>

        </Header>
    );

});

AppHeaderBar.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    toggleSidebar: PropTypes.func.isRequired,
    sidebarCollapsed: PropTypes.bool.isRequired,
};

const mapStateToProps = ({
    sidebarCollapsed, user,
}) => ({
    sidebarCollapsed,
    user,
});

const mapDispatchToProps = ({
    toggleSidebar: actions.sidebar.toggleSidebar,
    removeAuthenticationData: thunks.user.removeAuthenticationData,
});

export default connect(mapStateToProps, mapDispatchToProps)(connectScreenSize(withRouter(AppHeaderBar)));
