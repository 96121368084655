import { lazy } from 'react';

import { getRoutesArray } from './manager';

const HomePage = lazy(() => import('~/domains/home/HomePage'));
const PsychologistFlowPage = lazy(() => import('~/domains/psychologist/PsychologistFlowPage'));
const PsychologistsListPage = lazy(() => import('~/domains/administrator/PsychologistsListPage'));
const PsychologistViewPage = lazy(() => import('~/domains/administrator/PsychologistViewPage'));
const ProfilePage = lazy(() => import('~/domains/psychologist/ProfilePage'));
const ConfigurationsPage = lazy(() => import('~/domains/administrator/ConfigurationsPage'));
const ScheduleAppointment = lazy(() => import('~/domains/psychologist/schedule/ScheduleAppointment'));

const CONFIGURATIONS = [
    {
        exact: true,
        path: '/configuracoes',
        menuKey: '/configuracoes',
        component: ConfigurationsPage,
        text: 'Configurações',
        icon: {
            name: 'settings',
        },
        roles: ['ADMINISTRATOR'],
    },
];

const SCHEDULE = [
    {
        exact: true,
        path: '/horarios-atendimento',
        menuKey: '/horarios-atendimento',
        component: ScheduleAppointment,
        text: 'Horários de atendimento',
        icon: {
            name: 'dateRange',
        },
        roles: ['PSYCHOLOGIST'],
    },
];

const ROUTES = [
    {
        path: '/',
        menuKey: '/',
        component: HomePage,
        exact: true,
    },
    {
        path: '/psicologo/inicio',
        menuKey: '/psicologo/inicio',
        component: PsychologistFlowPage,
        text: 'Início',
        icon: {
            name: 'dashboard',
        },
        roles: ['PSYCHOLOGIST'],
    },
    {
        path: '/psicologos',
        menuKey: '/psicologos',
        component: PsychologistsListPage,
        text: 'Psicólogos',
        icon: {
            name: 'list',
        },
        roles: ['ADMINISTRATOR'],
        exact: true,
    },
    {
        path: '/psicologos/:psychologistId/visualizar',
        menuKey: '/psicologos/:psychologistId/visualizar',
        component: PsychologistViewPage,
        roles: ['ADMINISTRATOR'],
    },
    {
        path: '/perfil',
        component: ProfilePage,
        exact: true,
        roles: ['PSYCHOLOGIST'],
    },
    ...CONFIGURATIONS,
    ...SCHEDULE,
];

export default ROUTES;

export const allRoutes = getRoutesArray({
    group: 'root',
    routes: ROUTES,
});
